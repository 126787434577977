.App {
  text-align: center;
}

.resizer { transition: all .15s ease-in-out; }
.resizer:hover { transform: scale(1.4); }

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background: rgb(0,0,0);
  background: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(138,138,138,1) 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
